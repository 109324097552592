<template>
  <div>
    <Managebanners />
  </div>
</template>
<script>
import Managebanners from "@/components/banners/Managebanners";
export default {
  components: {
    Managebanners,
  },
  created() {
  },
};
</script>c